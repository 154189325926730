import { useConfig } from "../../utils/AppUtils";

import EZBetLogo from "applications/ezbet/img/logoarrow_48.gif";
import InvertedLogo from "assets/img/icons/inverted-spinning-logo.gif";
import Logo from "assets/img/icons/spinning-logo.gif";

const GenericLoadingLogo = () => (
  // Logo to be centered vertically and horizontally
  <div style={{ alignContent: "center", display: "flex", height: "100%", justifyContent: "center", width: "100%" }}>
    <div style={{ margin: "auto" }}>
      <img alt="Loading..." src={Logo} />
    </div>
  </div>
);

const EZBetLoadingLogo = () => (
  // Logo to be centered vertically and horizontally
  <div style={{ alignContent: "center", display: "flex", height: "100vh", justifyContent: "center", width: "100vw" }}>
    <div style={{ margin: "auto" }}>
      <img alt="Loading..." height="48px" src={EZBetLogo} width="48px" />
    </div>
  </div>
);

const HommeLoadingLogo = () => (
  // Logo to be centered vertically and horizontally
  // Logo to be centered vertically and horizontally
  <div
    style={{
      alignContent: "center",
      backgroundColor: "#050505",
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <div style={{ margin: "auto" }}>
      <img alt="Loading..." src={InvertedLogo} />
    </div>
  </div>
);

const LoadingLogo = () => {
  const config = useConfig();

  // legacy flag
  const specificLoadingLogoConfig = config.specificLoadingLogoConfig;

  if (specificLoadingLogoConfig === "EZBET") {
    return <EZBetLoadingLogo />;
  }
  if (specificLoadingLogoConfig === "HOMME") {
    return <HommeLoadingLogo />;
  }

  return <GenericLoadingLogo />;
};

export default LoadingLogo;
